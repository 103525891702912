export const m = 
{ 
  lqlj: "確定領取",
sqinfo: "要使用您個人賬戶的功能，請使用錢包授權",
sqbtn: "錢包授權",
sqload: "授權中...",
dltt: "登錄到您的賬戶",
dlinfo: "要使用您個人賬戶的功能，請點擊鏈接錢包",
dlbtn: "鏈接錢包",
bdtt: "綁定上級",
bdinfo: "請輸入推薦人錢包地址",
bdbtn: "確認綁定",
bdload: "綁定中...",
bangsuc:"綁定成功",
lqsg: "領取成功",
lqsb: "領取失敗",
day: "天",
shi:"時",
fen:"分",
miao:"秒",
fzcg:"複製成功",
fzsb:"複製失敗",
yhdj:"用戶等級",
zwkf:"提示：暫未開放！",
mysj:"沒有數據",
wdyqlj:"我的邀請鏈接",
wdtjdz:"我的推薦地址",
fz:"複製",
home:"首頁",
qr:"確認",
ny:"您有",
wcqbqr:"完成錢包確認",
dqsl:"授權數量",
cyje:"參與金額",
qsrcyje:"請輸入參與金額",
zfsl:"支付數量",
qbye:"錢包餘額",
ljcy:"立即參與",
wdzc:"我的資產",
klqzc:"可領取資產",
jrsy:"今日收益",
ljsy:"累計收益",
lq:"領取",
qrlq:"確認領取",

wddd:'我的訂單',
yxz:"運行中",
ycj:"已出局",
cysj:"參與時間",
zfje:"支付金額",
cjjd:"出局節點",

wdsq:"我的社區",
wddj:"我的等級",
sfjd:"是否是節點",
shi:"是",
fou:"否",
kgdj:"礦工等級",
wu:"無",
cjkg:"初級礦工",
zjkg:"中級礦工",
gjkg:"高級礦工",
cjkgs:"超級礦工",
cskg:"創世礦工",
slzx:"算力中心",
grztslz:"個人在投算力值",
grztrslz:"個人總投入算力值",
ydztslz:"一代在投算力值",
erztslz:"二代在投算力值",
xqztslz:"小區在投算力值",
sqztslz:"社區在投算力值",
sqxzyj:"社區新增業績",
wdfx:"我的分享",
wxyh:"無效用戶",
yxyh:"有效用戶",
cycg:"參與成功",
cysb:"參與失敗",
lyjh:"鏈遊聚合",
kfz:"開發中...",
gglb:"公告列表",
ggtt:"MICO震撼上線！",
ggnr:"熱烈慶祝全球首個鏈遊聚合平臺，正式上線，正式開啟token算力吸粉1.0時代，一起打開鏈遊元宇宙的財富寶庫。",
dhcg:"兌換成功",
dhsb:"兌換失敗",
bj:"幣價",
zytz:"重要通知",
wgxlk:"為感謝新老玩家對平臺的支持和信任，為感謝玩家們一直以來對平臺的寬容和厚愛。恰逢大陸新年即將來臨，平臺為共識者發放福利，提前預祝大陸社區新年快樂，吉祥如意.",
jrq:"即日起區塊將提升400000token每日暴塊分發算力.新年結束，區塊恢復200000token每日.",
syyz:"MICO運營組",
ggxx:"MICO 官方恭祝全球華人新年快樂，蛇年大吉。2025新春之際，為更好拓展市場，提高頭礦期收益，即日起每日區塊爆塊鑄幣增加至100萬枚token一個塊，為期至3月底再做調整，忘各DAO組織抓緊機會，努力布道，春種秋收。一起迎接MICO帶來的財富盛宴。"












}