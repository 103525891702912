export const m = 
{
    lqlj: "確定受領です",
  sqinfo: "個人アカウントの機能を利用するには、ワレットライセンスを利用します。",
  sqbtn: "ワレットライセンスです",
  sqload: "ライセンスしています...",
  dltt: "あなたのアカウントに登録されます。",
  dlinfo: "個人アカウントの機能を利用するには、リンクウォレットをクリックします。",
  dlbtn: "リンクウォレットです",
  bdtt: "上級機関と結びつけることです",
  bdinfo: "推薦人のウォレットのアドレスを入力します。",
  bdbtn: "バインディング確認です",
  bdload: "バインディング中です...",
  bangsuc:"バインディング成功です",
  lqsg: "受領成功です",
  lqsb: "受領に失敗しました",
  day: "天です",
  shi:"時です",
  fen:"分けます",
  miao:"秒です",
  fzcg:"複製成功です",
  fzsb:"コピー失敗です",
  yhdj:"ユーザーレベルです",
  zwkf:"ヒント:まだ開いていません!！",
  mysj:"データがありません",
  wdyqlj:"私の招待リンクです",
  wdtjdz:"私のお勧めの住所です",
  fz:"複製します",
  home:"トップページです",
  qr:"確認します",
  ny:"お持ちです",
  wcqbqr:"ウォレット確認完了です",
  dqsl:"ライセンス数です",
  cyje:"参加金額です",
  qsrcyje:"参加金額を入力願います",
  zfsl:"支払い数量です",
  qbye:"財布の残高です",
  ljcy:"直ちに参加します",
  wdzc:"私の資産です",
  klqzc:"受領可能資産です",
  jrsy:"今日の収益です",
  ljsy:"累積収益",
  lq:"受け取ります",
  qrlq:"受領確認です",

  wddd:'私の註文です',
  yxz:"運行中です",
  ycj:"アウトです",
  cysj:"参加時間です",
  zfje:"支払い金額です",
  cjjd:"アウトノードです",

  wdsq:"私のコミュニティです",
  wddj:"私のランクです",
  sfjd:"ノードです",
  shi:"そうです",
  fou:"いいえ",
  kgdj:"鉱夫階級です",
  wu:"ありません",
  cjkg:"鉱夫初心者です",
  zjkg:"中級鉱夫です",
  gjkg:"高級鉱夫です",
  cjkgs:"スーパー鉱夫です",
  cskg:"鉱夫創世です",
  slzx:"算力センターです",
  grztslz:"個人の計算力です",
  grztrslz:"個人総投入計算力値です",
  ydztslz:"力を投げていました",
  erztslz:"二世は力の数値を計算します",
  xqztslz:"セルは力の値を投げます",
  sqztslz:"コミュニティは力の数値を計算します",
  sqxzyj:"コミュニティの業績が増加しました",
  wdfx:"私の分かち合いです",
  wxyh:"無効ユーザーです",
  yxyh:"有効ユーザーです",
  cycg:"参加できました",
  cysb:"失敗しました",
  lyjh:"連鎖連鎖重合です",
kfz:"開発中です…",
gglb:"公告の一覧です",
ggtt:"MICO is launched! ",
ggnr:"Warmly celebrate the world's first chain game aggregation platform, officially launch the era of token computing power 1.0, and open the treasure trove of chain game metaverse.",
dhcg:"両替成功です",
dhsb:"両替失敗です",
bj:"貨幣価格",
zytz:"Important Notice",
wgxlk:"To thank new and old players for their support and trust in the platform, and to thank players for their tolerance and love for the platform. As the mainland Chinese New Year is approaching, the platform distributes benefits to those who agree, wishing the mainland community a happy and prosperous New Year in advance.",
jrq:"Starting today, the block will increase the daily block distribution hash power by 400,000 tokens. At the end of the New Year, the block will resume 200,000 tokens per day.",
syyz:"MICO Operations Team",
ggxx:"MICO officially wishes Chinese people around the world a happy New Year and good luck in the Year of the Snake. On the occasion of the Chinese New Year in 2025, in order to better expand the market and increase the income of the first mining period, the daily block block block minting will be increased to 1 million tokens per block starting from today, and adjustments will be made until the end of March. All DAO organizations should seize the opportunity and work hard to spread the message, sowing in spring and harvesting in autumn. Let's welcome the wealth feast brought by MICO together."



}